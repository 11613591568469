/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import store from '@/store';

export default {
  beforeEach: (to, from, next) => {
    if (to.name === 'logout') {
      store.dispatch('AuthenticationStore/logout');
    } else if (!store.getters['AuthenticationStore/getUser']) {
      store.dispatch('AuthenticationStore/login');
    } else {
      next();
    }
  },
};
