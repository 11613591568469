/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */

export default [
  {
    path: '/',
    component: () => import('@/views/Home.vue'),
    children: [
      {
        path: '/',
        name: 'summary',
        component: () => import('@/views/Summary.vue'),
      },
      {
        path: '/payment',
        name: 'payment',
        component: () => import('@/views/Payment.vue'),
      },
      {
        path: '/documents',
        name: 'documents',
        component: () => import('@/views/Documents.vue'),
      },
      {
        path: '/logout',
        name: 'logout',
      },
    ],
  },
];
