/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */

const formatters = {
  currencyFormatter: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }),
};

const currencyFilters = {
  toUSD(value) {
    return formatters.currencyFormatter.format(value);
  },
};

export default currencyFilters;
