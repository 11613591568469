/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */

import Vue from 'vue';
import App from '@/views/App.vue';
import EmptyComponent from '@/views/EmptyComponent.vue';
import router from '@/router/index';
import store from '@/store/index';
import Vuelidate from 'vuelidate';
import currencyFilters from '@/filters/currencyFilters';
import dateFilters from '@/filters/dateFilters';
import '@bhgpdi/bhg-vue-components';
import '@bhgpdi/bhg-vue-components/dist/npm-vue-components.css';

Vue.config.productionTip = false;

Vue.use(Vuelidate);

Vue.filter('toUSD', currencyFilters.toUSD);
Vue.filter('toDate', dateFilters.toDate);
Vue.filter('toUTCDate', dateFilters.toUTCDate);

// Used because msal will load the entire app in an iframe. Stupid msal
const isIFrame = window !== window.parent && !window.opener;

export default new Vue({
  router,
  store,
  el: '#app',
  render: view => view(isIFrame ? EmptyComponent : App),
});
