/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */

import Vue from 'vue';
import Vuex from 'vuex';
import BorrowerModule from './modules/BorrowerModule';
import LoanModule from './modules/LoanModule';
import AuthenticationStore from './modules/AuthenticationModule';
import PaymentHistoryModule from './modules/PaymentHistoryModule';
import PaymentScheduleModule from './modules/PaymentScheduleModule';
import PaymentModule from './modules/PaymentModule';
import DocumentModule from './modules/DocumentModule';
import LoadingModule from './modules/LoadingModule';
import ErrorModule from './modules/ErrorModule';
import FeatureFlagModule from './modules/FeatureFlagModule';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    BorrowerModule,
    LoanModule,
    AuthenticationStore,
    PaymentHistoryModule,
    PaymentScheduleModule,
    PaymentModule,
    DocumentModule,
    ErrorModule,
    LoadingModule,
    FeatureFlagModule,
  },
});

const init = async () => {
  store.dispatch('startLoading');
  await store.dispatch('AuthenticationStore/initialize');
  await store.dispatch('getFeatureFlags');
};


// only initialize when not in iframe - stupid msal
if (window === window.parent || window.opener) {
  init();
}


export default store;
