/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */

import Vue from 'vue';
import Router from 'vue-router';
import routes from '@/router/routes';
import events from '@/router/events';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
});

router.beforeEach(events.beforeEach);

export default router;
